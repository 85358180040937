import styled from "@emotion/styled"
import { theme } from '../../../util/theme'

export const Project = styled.div`
  padding: 0 0 1.75em 0;
  border-bottom: 1px solid ${theme.colors.gray};
  
  a{
    text-decoration:none;
  }
  h4{
    margin-top: 3em;
  }
  h3 {
    padding: 0;
    color: black;
    
    img{
      transform: rotate(135deg);
      margin: 0 0.75em 0 0;
    }
  } 

  &:hover{
    h2{
      color: ${theme.colors.orange};
    }
    h3 {
      opacity: 1;
    }
  }
`;