import PropTypes from "prop-types"
import React from "react"
import _ from "lodash"

import {
  Project
} from "./styles"
import { Title2, Title3, Title4 } from '../../styled'

import arrow from "../../../images/Button_Crop_upperleft.svg"

class Article extends React.Component {
  constructor() {
    super();
    this.state = { active: false };

    this.textChange = this.textChange.bind(this);
  }

  textChange() {
    this.setState({ active: !this.state.active });
  }

  returnCategories(){
    let list = [];

    _.forEach(this.props.categories, function(value,key){
      list.push(value.name);
    })
    
    return list.join(", ");
  }

  render() {
    return (
      <Project>
        <Title4>
          {this.returnCategories()}
        </Title4>
        <a href={this.props.link}>
          <Title2>
            {this.props.title}
          </Title2>
          <Title3 className={"learnMore"}>
              View
              <img src={arrow} alt="Orange right arrow" />
          </Title3>
        </a>
      </Project>
    );
  }
}
export default Article;

Article.propTypes = {
  style: PropTypes.object,
  theme: PropTypes.object
}