import React from "react"
import { graphql } from 'gatsby'
import Layout from "../components/layout"
import SEO from "../components/seo"

import ArticleList from "../components/index"

const IndexPage = ({ data }) => {

  const {
    allWpProject,
    allWpPage,
    allWpCategory
  } = data;

  return (
    <Layout>
      <SEO title="GH Labs" />
      <ArticleList
        body={allWpPage.nodes[0].home}
        data={allWpProject}
        options={[allWpCategory.nodes]}
        type={false}
      />
    </Layout>
  )
}

export default IndexPage

export const indexQuery = graphql`
query IndexQuery {
  allWpProject {
    nodes {
      date
      title
      slug
      project {
        contact: projectContact
        content: projectContent
        items: projectItems
      }
      categories {
        nodes {
          slug
          name
        }
      }
    }
  }
  allWpPage {
    nodes {
      home {
        homeContent
        homeTitle
      }
    }
  }
  allWpCategory(filter: {slug: {ne: "uncategorized"}}) {
    nodes {
      value:slug
      label:name
    }
  }
}
`;