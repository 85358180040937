import PropTypes from "prop-types"
import React from "react"
import _ from 'lodash'
import Article from "./article"
import Select from 'react-select'


import { Menu, Head, ModalHead } from './styles'
import { Wrapper, Title, About } from '../styled'

// import Modal from 'react-modal'
// import ContactForm from '../ContactForm'

import icon_X from "../../images/Icon_X.svg"

class ArticleList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      dateAsc: true, //True by default
      showTags: [], //Categories that will be shown
      showTypes: "",
      articles: this.props.data.nodes,
      contactModal:false,
    }
    this.selectRef = React.createRef();

    this.returnArticles = this.returnArticles.bind(this);
    this.sortBy = this.sortBy.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
    // this.modalIsOpen = this.modalIsOpen.bind(this);
    // this.modalIsClosed = this.modalIsClosed.bind(this);
    this.sortBy(true);
  }

  // Sorts the data by target in the asc or dec order
  sortBy(asc) {
    let tempList = this.state.articles;

    tempList.sort((a, b) => {
      if (!asc)
        return (a.date > b.date) ? 1 : ((a.date < b.date) ? -1 : 0);
      else
        return (a.date > b.date) ? -1 : ((a.date < b.date) ? 1 : 0);
    })

    this.setState({ articles: tempList, dateAsc: asc });
  }

  //Adds or removes articles from showTag list
  handleInputChange(list) {
    let tempList = this.state.showTags;

    if (!list.length) {
      this.setState({ showTags: [] });
    }

    if (list.length < this.state.showTags.length) {
      tempList = [];
    }

    for (const [index, value] of list.entries()) {
      if (!tempList.includes(value.value)) {
        tempList = [...tempList, value.value];
      }
    }

    this.setState({ showTags: tempList });
  }

  // componentDidUpdate() {
  //   console.log(this.selectRef);
  // }

  //Checks if article should be shown
  checkTags(tags) {
    //If the list is empty show all
    if (!this.state.showTags.length) {
      return true;
    }

    let show = false;
    //If list is not empty check if relevant tags are present
    _.forEach(this.state.showTags, function(value){
      show ||= !(_.isEmpty(_.find(tags, {'slug' : value})));
    })

    return show;
  }

  // modalIsOpen(){
  //   this.setState({contactModal: true});
  // }

  // modalIsClosed(){
  //   this.setState({contactModal: false});
  // }

  returnArticles() {
    var renderedArticles = [];

    for (const [index, values] of this.state.articles.entries()) {
      if (this.checkTags(values.categories.nodes)) {
        renderedArticles.push(
          <Article
            date={new Date(values.date)}
            title={values.title}
            authors={values.project.contact}
            download={values.project.download}
            categories={values.categories.nodes}
            text={values.project.content}
            key={index}
            link={"/" + values.slug}
          />
        )
      }
    }

    return renderedArticles;
  }
  
  render() {
    const dateOptions = [{ value: true, label: "Most Recent" }, { value: false, label: "Least Recent" }];

    return (
      <Wrapper>
        <Head>
          <Title className="xbold">{this.props.body.homeTitle}</Title>
          <About dangerouslySetInnerHTML={{__html: this.props.body.homeContent}}/>
        </Head>
        <Menu tagNum={this.state.showTags.length}>
          <div className={"date"}>
            <Select
              isSearchable={false}
              isClearable
              options={dateOptions}
              className="basic-single"
              classNamePrefix="select"
              placeholder="Sort By Date"
              onChange={(list) => this.sortBy(list? list.value : true)}
            />
          </div>
          <div className={"bar"}/>
          <div className={"tag"} ref = {this.selectRef}>
            <Select
              isMulti
              options={this.props.options[0]}
              className="basic-multi-select"
              classNamePrefix="select"
              placeholder={"Filter By Topic"}
              onChange={(list) => this.handleInputChange(list)}
            />
          </div>
        </Menu>
        {this.returnArticles()}
        {/* <Modal
          isOpen={this.state.contactModal}  
          onRequestClose={this.modalIsClosed}
          shouldCloseOnEsc
          shouldCloseOnOverlayClick
          shouldFocusAfterRender
        >
          <ModalHead>
            <img src={icon_X} alt="Orange Exit Button" onClick={this.modalIsClosed}/>
            <p>Please send questions through our Contact Form alongside your press affiliation, best contact, and applicable deadline.</p>
          </ModalHead>
          <ContactForm/>
        </Modal> */}
      </Wrapper>
    );
  }
}
export default ArticleList;

ArticleList.propTypes = {
  style: PropTypes.object,
  theme: PropTypes.object
}