import styled from "@emotion/styled"
import { mq } from "../../util/theme";

export const Menu = styled.div`
  border-bottom: 5px solid #EFEFEF;
  padding-bottom: 1rem;
  
  ${mq[1]}{
    display: flex;
    align-items: top;
    & > div{
      margin:0 .25em;
      &:first-of-type{
        margin-left:-10px;
      }
      &:last-child{
        margin-right:0;
      }
      &.bar{
        display: inline-block;
        width: 1px;
        height: 16px;
        background-color: ${props => props.theme.colors.black};
        align-self: center;
        &:last-child{
          display:none;
        }
      }
      &.date{
        min-width:140px;
      }
      &.tag{
        width: ${props => props.tagNum? (props.tagNum * 230) + "px" : "230px"};
      }
      &.type{
        min-width:165px;
      }
    }
  }

  
  //Select Styles
  letter-spacing: 0.75px;
  font-size: 13px;
  .select{
    &__control{
      border: none;
      cursor: pointer;
      min-height: 22px;
      &:focus {
        outline: none;
        box-shadow:none;
      }
      &--is-focused{
        border: none;
        box-shadow:none;
      }
    }
    &__single-value{
      overflow:visible;
      color:black;
      text-transform: none;
    }
    &__placeholder{
      color: black;
    }
    
    &__indicator{
      &-separator{
        display:none;
      }
    }
    &__clear-indicator{
      padding 0 8px;
    }
    &__dropdown-indicator{
      display:none;
    }
    &__multi{
      &-value{
        background: #DD571B;
        border-radius: 10px;
        color: white;
        &__label{
          color:white;
        }
        &__remove:hover{
          background-color: #DD571B;
          color: white;
          border-radius: 10px;
        }
        &__label{
          text-transform: none;
        }
      }
    }
    &__value-container{
      text-transform: uppercase;
      &>div{
        padding: 0;
      }
    }
    &__option{
      cursor: pointer;
      &:active{
        background-color: ${props => props.theme.colors.lightOrange};
      }
      &--is-focused{
        background-color: ${props => props.theme.colors.lightOrange};
      }
      &--is-selected{
        background-color: ${props => props.theme.colors.orange};
      }
    }
  }
`;

export const Head = styled.div`
  position:relative;
  img{
    position: absolute;
    right: 0;
    height: 100%;
  }
  h1, p{
    position: relative;
    z-index: 1;
  }
`;

export const ModalHead = styled.div`
  img{
    float: right;
    cursor: pointer;
  }
`;